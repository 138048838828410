import React, { Component } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { navigate } from "gatsby";

import {
  Container, Row, Col, Form, FormGroup, Label, Input, Button,
} from "reactstrap";

class LoginPage extends Component {
  componentDidMount() {
    // start firebase
    const config = {
      apiKey: process.env.FIREBASE_API_KEY,
      authDomain: process.env.FIREBASE_AUTH_DOMAIN,
      projectId: process.env.FIREBASE_PROJECT_ID,
      storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    };
    // Check if app has already been initialized
    if (firebase.apps.length === 0) {
      firebase.initializeApp(config);
    }
  }

  onSubmit(event) {
    event.preventDefault();

    // get form values
    const formData = new FormData(event.target);
    const [username, pwd] = [formData.get("email"), formData.get("password")];

    firebase.auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => firebase.auth().signInWithEmailAndPassword(username, pwd)
        .then(() => {
          navigate("/admin");
        }))
      .catch((error) => {
        if (error.code === "auth/wrong-password") {
          alert("Nombre de usuario o contraseña no validos");
        }
      });
  }

  render() {
    return (
      <Container className="mt-5">
        <Row>
          <Col>
            <div>
              <h2 className="text-center">Orozco Y Laverde</h2>
              <p className="lead text-center">Administracion Web</p>
              <Form onSubmit={this.onSubmit}>
                <FormGroup row>
                  <Col sm={{ size: 6, offset: 3 }}>
                    <Label for="exampleEmail">Usuario</Label>
                    <Input type="email" name="email" required />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={{ size: 6, offset: 3 }}>
                    <Label for="examplePassword">Contrase&ntilde;a</Label>
                    <Input type="password" name="password" required />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={{ size: 6, offset: 3 }} className="text-right mt-3">
                    <Button type="submit" className="btn btn-lg">Login</Button>
                  </Col>
                </FormGroup>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default LoginPage;
